import { useNavigate } from "react-router-dom";
// import Headler from "./Headler";
import Footer from "./Footer";

function Privacy() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center w-full  select-none">
      <div className="flex flex-row px-20 py-4 items-center justify-between top-0 w-screen z-20  backdrop-blur-sm bg-white/80 border-b-2">
        <div className="flex flex-row items-center">
          {/* <img className="h-12 w-12" src="/assets/clogo.png" alt="logo" /> */}
          <div
            onClick={() => navigate("/")}
            className=" text-2xl font-semibold hover:cursor-pointer"
          >
            SeeMyTip
          </div>
        </div>
        {/* <div className="flex flex-row items-center justify-between gap-6 text-sm pt-3">
          <a href="#problem" className="p-2">
            PROBLEM
          </a>
          <a href="#solution" className="p-2">
            SOLUTION
          </a>
          <a href="#how" className="p-2">
            HOW
          </a>
          <a href="#cost" className="p-2">
            COST
          </a>
        </div> */}
        <div
          onClick={() => navigate("/")}
          className="flex flex-row items-center p-2 gap-2"
        >
          <div className="text-sm mr-2">GET THE APP</div>
          <img className="h-6 w-6" src="/assets/app.png" alt="logo" />
          <img className="h-6 w-6" src="/assets/app1.png" alt="logo" />
        </div>
      </div>
      <div className="max-w-4xl mx-auto p-6 bg-white text-gray-800">
        <h1 className="text-3xl font-bold text-center mb-8 ">Privacy Policy</h1>

        <div className="space-y-6">
          <div className="border rounded-lg p-4 bg-gray-50">
            <h2 className="text-xl font-semibold ">
              1. Information We Collect
            </h2>
            <div className="mt-4 text-gray-700 space-y-3">
              <p>
                At cmytip, we are committed to transparency about the
                information we collect to provide our AI-powered product label
                understanding service. Our data collection is designed to
                enhance user experience while maintaining stringent privacy
                standards.
              </p>

              <h3 className="font-semibold text-lg mt-4">
                Personal Information
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>Email address (required for account creation)</li>
                <li>Full name (optional)</li>
                <li>User-provided profile information</li>
                <li>Communication preferences</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Device and Technical Information
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>Device type and model</li>
                <li>Operating system version</li>
                <li>IP address</li>
                <li>Browser type</li>
                <li>Screen resolution</li>
                <li>Language settings</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Usage and Interaction Data
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>App feature usage patterns</li>
                <li>Frequency of product label scans</li>
                <li>Time spent in different app sections</li>
                <li>Error logs and diagnostic information</li>
                <li>Performance metrics</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Image and AI Processing Data
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>Product label images uploaded by users</li>
                <li>Temporary image storage for AI analysis</li>
                <li>AI-generated insights and interpretations</li>
                <li>Anonymized image metadata</li>
              </ul>

              <p className="italic text-sm text-gray-600 mt-4">
                * All images are processed temporarily and securely deleted
                after analysis * No personally identifiable information is
                stored with image data * We use advanced encryption to protect
                all collected information
              </p>
            </div>
          </div>

          <div className="border rounded-lg p-4 bg-gray-50">
            <h2 className="text-xl font-semibold ">
              2. How We Collect Information
            </h2>
            <div className="mt-4 text-gray-700 space-y-3">
              <p>
                cmytip employs multiple sophisticated methods to collect
                information, ensuring both comprehensive service delivery and
                user privacy.
              </p>

              <h3 className="font-semibold text-lg mt-4">
                Direct Collection Methods
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>User registration and account creation</li>
                <li>Manual input of user preferences</li>
                <li>Direct user feedback and support interactions</li>
                <li>Voluntary survey and research participation</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Automated Collection Technologies
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>App usage tracking analytics</li>
                <li>Device and browser information collection</li>
                <li>Cookies and similar tracking technologies</li>
                <li>Performance and crash reporting tools</li>
                <li>AI-powered image recognition and processing</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Third-Party Data Sources
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>App store download and usage statistics</li>
                <li>Aggregated and anonymized usage analytics</li>
                <li>Performance monitoring services</li>
                <li>Crash reporting platforms</li>
              </ul>

              <p className="text-sm text-red-600 mt-4">
                We are committed to minimal data collection and always
                prioritize user consent and privacy.
              </p>
            </div>
          </div>

          <div className="border rounded-lg p-4 bg-gray-50">
            <h2 className="text-xl font-semibold">
              3. Use of Collected Information
            </h2>
            <div className="mt-4 text-gray-700 space-y-3">
              <p>
                The information we collect serves multiple critical purposes in
                delivering and improving the cmytip service.
              </p>

              <h3 className="font-semibold text-lg mt-4">
                Core Service Delivery
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>AI-powered product label analysis</li>
                <li>Personalized user experience</li>
                <li>Account management and authentication</li>
                <li>Providing requested services and features</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Continuous Improvement
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>AI model training and refinement</li>
                <li>Product feature development</li>
                <li>User experience optimization</li>
                <li>Performance and reliability enhancement</li>
              </ul>

              <h3 className="font-semibold text-lg mt-4">
                Communication and Support
              </h3>
              <ul className="list-disc list-inside pl-4">
                <li>Critical app updates and notifications</li>
                <li>Customer support communications</li>
                <li>Security and privacy alerts</li>
                <li>Optional marketing communications (with consent)</li>
              </ul>

              <p className="text-sm italic text-gray-600 mt-4">
                We never sell or rent your personal information to third
                parties.
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 text-center text-gray-600">
          <p>Last Updated: December 8, 2024</p>
          <p className="text-sm">© 2024 cmytip. All rights reserved.</p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Privacy;
