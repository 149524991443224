import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </div>
  );
}

export default App;
