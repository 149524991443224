import { useState } from "react";
import Headler from "./Headler";
import Footer from "./Footer";

function App() {
  const [email, setEmail] = useState("");
  const [subscribed, setSubscribed] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What information does the app provide?",
      answer:
        "All ingredents information including what it is, benefits and risks, in details.",
    },
    {
      question: "How to use it?",
      answer: "Just need product front and ingredent label pictures.",
    },
    {
      question: "can I cancel it or delete my account?",
      answer:
        "You can cancel or delete your account anytime you want in the app.",
    },
    {
      question: "I have more questions?",
      answer: "Please feel free to reach us at care@cmytip.com.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const subscribe = async () => {
    if (!email) {
      alert("Please enter email");
      return;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(email)) {
      alert("Please enter valid email");
      return;
    }

    try {
      const res = await fetch("https://cmytip.com/subscribe", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      if (res.status === 200) {
        setSubscribed(true);
        setEmail("");
        // alert("Subscribed successfully");
      } else {
        alert("Failed to subscribe");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col items-center w-full  select-none">
      <Headler />
      <div
        id="problem"
        className="h-[560px] w-full p-12 flex flex-row justify-center items-center"
      >
        <div className=" w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-6 w-6" src="/assets/problem.png" alt="logo" />
            <div className="mt-2">What is the problem?</div>
          </div>
          <div className="text-6xl font-bold">
            We don't understand a lot product ingredients
          </div>
          <div className="text-[18px] font-light mt-8 ">
            The risks, harmful side effects, benefits, long term effects, etc.
            We just blindly trust the regulations and the companies.
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2 ">
          <img className="h-[420px]" src="/assets/homebg.png" alt="logo" />
          <img className="h-[420px]" src="/assets/homebg1.png" alt="logo" />
          {/* <img className="h-[420px]" src="/assets/homebg.png" alt="logo" /> */}
        </div>
      </div>
      <div className="h-[460px] w-full bg-blue-50">
        <section
          id="container1"
          className="bg-[url('./assets/market.png')] bg-fixed bg-center bg-cover w-full h-full flex flex-col justify-center items-center rounded"
        >
          <h1 className="text-white text-center text-6xl font-bold">
            What is the solution?
          </h1>
        </section>
      </div>
      <div
        id="solution"
        className="h-[660px] w-full p-12 flex flex-row justify-center items-center"
      >
        <div className="flex flex-col w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-6 w-6" src="/assets/solution1.png" alt="logo" />
            <div className="mt-2">Expand our intelligence!</div>
          </div>
          <div className="text-6xl font-bold">
            Let AI help you understand pros and cons of the ingredients
          </div>
          <div className="text-[18px] font-light mt-8 ">
            What they are, what benefits and risks are, can it be used long
            term... Take a picture and that's all, the app will do the rest. It
            can be food, beverage, cosmetics, household products, etc.
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2 ">
          <img className="h-[420px]" src="/assets/label.png" alt="logo" />
          <img className="h-[420px]" src="/assets/label1.png" alt="logo" />
        </div>
      </div>
      <div
        id="how"
        className="h-[660px] w-full p-12 flex flex-row justify-center items-center bg-slate-100"
      >
        <div className="flex flex-col w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-6 w-6" src="/assets/app.png" alt="logo" />
            <img className="h-6 w-6" src="/assets/app1.png" alt="logo" />
            <div className="mt-2">Try free. No credit card.</div>
          </div>
          <div className="text-6xl font-bold">
            Cmytip is leveraging AI to help you.
          </div>
          <div className="text-[18px] font-light mt-8 ">
            Download for iOS or Android, try it for free. No credit card. Just
            take a picture.
          </div>
          <div className="flex flex-row gap-4 mt-6">
            <input
              type="email"
              placeholder="Enter your email"
              className="p-2 px-4 w-96 rounded border"
              value={email}
              onChange={(e) => setEmail(e.target.value || "")}
            />
            <button
              onClick={subscribe}
              className="py-2 px-4 bg-red-500 text-white rounded"
            >
              Subscribe
            </button>
          </div>
          <div className="h-6 mt-2 px-2">
            {subscribed && (
              <div className="text-green-500 text-xs">
                Subscribed successfully
              </div>
            )}
          </div>
          <div className="">100K+ people are already using it. Join them.</div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2 ">
          <img className="h-[420px]" src="/assets/screenshot2.png" alt="logo" />
          <img className="h-[420px]" src="/assets/screenshot1.png" alt="logo" />
        </div>
      </div>
      <div
        id="cost"
        className="h-[660px] w-full p-12 flex flex-row justify-center items-center "
      >
        <div className="flex flex-col w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-7 w-7" src="/assets/cost.png" alt="logo" />
            <div className="mt-2">What is the price?</div>
          </div>
          <div className="text-6xl font-bold">
            The price of not knowing what you put in your body or use daily?
          </div>
          <div className="text-[18px] font-light mt-8 ">
            Only $9.99/month, cancel anytime. No questions asked. Protect your
            health and your family's, otherwise, someone would love to c you
            often.
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2 ">
          <img className="h-[420px]" src="/assets/doctor.png" alt="logo" />
          <img className="h-[420px]" src="/assets/cost1.png" alt="logo" />
        </div>
      </div>
      <div
        id="faq"
        className="h-[660px] w-full p-12 flex flex-row justify-center items-center bg-slate-100"
      >
        <div className="flex flex-col w-1/2">
          <div className="flex flex-row items-center py-2 gap-2">
            <img className="h-8 w-8" src="/assets/people.png" alt="logo" />
            <div className="mt-2">Questions?</div>
          </div>
          <div className="text-6xl font-bold">Is it worth the price?</div>
          <div className="text-[18px] font-light mt-8 ">
            Try it for free. No credit card. Take a picture and see the results
            yourself.
          </div>
          <div className="max-w-3xl mx-auto p-4">
            <h1 className="text-2xl font-bold text-center mb-6">
              Frequently Asked Questions
            </h1>
            <div className="space-y-4">
              {faqs.map((faq, index) => (
                <div key={index} className="border rounded shadow-md">
                  <button
                    className="w-full p-4 text-left text-lg font-medium bg-gray-100 hover:bg-gray-200"
                    onClick={() => toggleFAQ(index)}
                  >
                    {faq.question}
                  </button>
                  <div
                    className={`overflow-hidden transition-all duration-300 ${
                      activeIndex === index ? "max-h-40 p-4" : "max-h-0 p-0"
                    } bg-white`}
                  >
                    <p className="text-gray-700">{faq.answer}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="w-1/2 flex flex-row justify-center items-center gap-2 ">
          <img className="h-[420px]" src="/assets/screenshot2.png" alt="logo" />
          <img className="h-[420px]" src="/assets/screenshot1.png" alt="logo" />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
