import React from "react";

const Headler = () => {
  return (
    <div className="flex flex-row px-20 py-4 items-center justify-between top-0 w-screen z-20  backdrop-blur-sm bg-white/80 border-b-2">
      <div className="flex flex-row items-center">
        <img
          className="object-cover h-10 mr-3"
          src="/assets/clogo1.png"
          //   src="/assets/clogo4.png"
          alt="logo"
        />
        <div className=" text-2xl font-semibold">CMyTip</div>
      </div>
      <div className="flex flex-row items-center justify-between gap-6 text-sm pt-3">
        <a href="#problem" className="p-2">
          PROBLEM
        </a>
        <a href="#solution" className="p-2">
          SOLUTION
        </a>
        <a href="#how" className="p-2">
          HOW
        </a>
        <a href="#cost" className="p-2">
          COST
        </a>
        <a href="#faq" className="p-2">
          FAQ
        </a>
      </div>
      <a href="#how" className="flex flex-row items-center p-2 gap-2">
        <div className="text-sm mr-2">GET THE APP</div>
        <img className="h-6 w-6" src="/assets/app.png" alt="logo" />
        <img className="h-6 w-6" src="/assets/app1.png" alt="logo" />
      </a>
    </div>
  );
};

export default Headler;
