import React from "react";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className=" bg-slate-50 pt-20 pb-28 flex justify-center w-screen h-full text-sm  text-slate-500 z-10 border-t-2 ">
      <div className="flex flex-col">
        <div className="flex flex-row gap-8 ">
          <a className="hover:underline" href="/">
            Home
          </a>
          <a className="hover:underline" href="/privacy">
            Privacy Policy
          </a>
          <a className="hover:underline" href="/terms">
            Terms of Service
          </a>
          {/* <a className="hover:underline" href="/contact">
          Feedback
        </a> */}
        </div>
        <div className="flex flex-col mt-10">
          <div className="flex items-center gap-3">
            <div className="h-8 w-8">
              <img
                className="object-cover opacity-70"
                src="/assets/clogo2.png"
                alt="logo"
              />
            </div>
            <div className=" md:text-xl font-black uppercase">CMYTIP </div>
            <div className="">Built with care in Seattle</div>
          </div>
          <div className="mt-3 text-xs font-extralight">
            @2023 - {currentYear} CMyTip | All Rights Reserved | Minute AI
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
